import messaging from '../../services/firebase/messaging';
import { SERVICE } from '../../services/tomoni/helper/device_tokens';

export default {
  computed: {
    meID() {
      return this.$store.getters["auth.me.id"];
    },
  },
  beforeMount() {
    this.$watch('meID', (newId) => {
      this.onMeChanged(newId);
    }, { immediate: true })
  },
  methods: {
    onMeChanged(id) {
      console.log(id + ' logged in');

      // Post token when user logged in and token available
      // ['default', 'granted', 'denied']
      if (Notification.permission !== 'denied' && id)
        messaging.getToken().then(token => {
          if (token) SERVICE.post('api/device-tokens', { token })
        });
    }
  }
};
