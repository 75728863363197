<template>
  <CFooter :fixed="false">
    <div class="w-100 d-flex">
      <div class="scroll-x scroll-hidden">
        <div v-if="me.organization" class="d-flex">
          <TMessage content="Organization" class="mr-1" color="muted">
            <template #suffix>:</template>
          </TMessage>
          <TMessage
            :content="me.organization.name"
            noTranslate
            bold
            color="primary"
          />
        </div>
        <!-- <TMessage v-else content="All" /> -->
        <SSelectOrganizationScope
          v-else
          class="select-w-scope d-inline-flex text-nowrap"
        />
      </div>
      <div class="ml-auto text-nowrap d-none d-sm-block">
        <span class="mx-1" target="_blank">Powered by</span>
        <a href="/">Tomoni Solution</a>
      </div>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    me() {
      return this.$store.getters["auth.me"];
    },
  },
  mounted() {
    if (this.me.organization) {
      this.$store.dispatch("auth.organizations.fetch.if-first-time");
    }
  },
};
</script>