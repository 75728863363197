<template>
  <CHeaderNavItem>
    <CHeaderNavLink>
      <CIcon size="xl" name="cil-cart" />
      <CBadge shape="pill" color="primary" v-if="ordersCount != 0">{{ordersCount}}</CBadge>
    </CHeaderNavLink>
  </CHeaderNavItem>
</template>
<script>
export default {
  data() {
    return { ordersCount: 0 };
  },
};
</script>
