<template>
  <CDropdown
    v-if="meID"
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon size="lg" class="text-primary" name="cil-user" />
        <TMessage :content="me.name" noTranslate :truncate="1" />
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <TMessage content="Workspaces" noTranslate bold />
    </CDropdownHeader>
    <CDropdownItem to="/admin" exact>
      <TMessage content="Admin" />
    </CDropdownItem>
    <CDropdownItem to="/customer" exact>
      <TMessage content="Customer" />
    </CDropdownItem>
    <CDropdownItem to="/sale/dashboard" exact>
      <TMessage content="Sale" />
    </CDropdownItem>
    <CDropdownItem to="/purchase/dashboard" exact>
      <TMessage content="Purchase" />
    </CDropdownItem>
    <CDropdownItem to="/accounting/dashboard" exact>
      <TMessage content="Accounting" />
    </CDropdownItem>
    <CDropdownItem to="/warehouse/dashboard" exact>
      <TMessage content="Warehouse" />
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem :to="`/${root_path}/me`" exact>
      <CIcon class="text-primary" name="cil-user" />
      <TMessage content="Account" />
    </CDropdownItem>
    <CDropdownItem @click="showIdToken">
      <CIcon class="text-info" name="cil-key" />
      <TMessage content="Get ID Token" />
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon class="text-danger" name="cil-lock-locked" />
      <TMessage content="Logout" />
    </CDropdownItem>
  </CDropdown>
  <CHeaderNavLink v-else to="/login" exact>
    <CIcon name="cil-user" /> Login
  </CHeaderNavLink>
</template>

<script>
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";
export default {
  name: "TheHeaderDropdownAccnt",
  props: {
    root_path: {
      type: String,
      default: "customer",
    },
  },
  computed: {
    me() {
      return this.$store.getters["auth.me"];
    },
  },
  created() {
    this.$store.dispatch("helper.countries.fetch.if-first-time");
    this.$store.dispatch("product.ecommerce_channels.fetch.if-first-time");
  },
  methods: {
    showIdToken() {
      this.$store.dispatch("auth.me.idtoken.toast");
    },
    showDeviceToken() {
      this.$store.dispatch("auth.me.deviceToken.toast");
    },
    logout() {
      this.$store.dispatch("auth.logout").then(() => {
        this.$redirect.toLogin();
        warehouseScope.set("");
      });
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
